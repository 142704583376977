import { Registration } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useContext } from "react"
import { backend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { LoadingSpinner } from "src/common/LoadingSpinner"

export type Registrations = {
    registrations: Registration[]
}

const context = createContext<Registrations>(undefined!)

export const useRegistrations = () => useContext(context)

export type RegistrationsProviderProps = {
    children: ReactNode
}

export const RegistrationsProvider: FC<RegistrationsProviderProps> = ({
    children
}) => {
    const event = useEvent()
    const registrationsRequest = backend.registrations.useGetAll<Registration[]>([event.clientId, event.id], {
        refetchInterval: 30_000
    })

    if (!registrationsRequest.data) {
        return <LoadingSpinner />
    }

    return <context.Provider children={children} value={{
        registrations: registrationsRequest.data,
    }} />
}