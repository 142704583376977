import { FormFieldValue, Registration } from "@marketpartner/backend-api"

export function formatBadge(format: string, registration: Registration, printCapsNames: boolean): string {

    const substitutions = {
        "%%first_name%%": getName(registration.firstName, printCapsNames),
        "%%last_name%%": getName(registration.lastName, printCapsNames),
        "%%token%%": registration.accessToken,
        "%%company%%": getCompany(registration.fields.company, printCapsNames)
    }
    let result = format
    for (const [key, replacement] of Object.entries(substitutions)) {
        result = result.replaceAll(key, replacement)
    }
    result = result.replace(/%%(.*?)%%/g, (_match, placeholder) => {
        const replaceValue = registration.fields[placeholder] || ""
        return String(replaceValue)
    })

    return result
}

function getName(name: string | null, printCapsNames: boolean): string {
    if (!name) return ""
    return printCapsNames ? name.toUpperCase() : name
}

function getCompany(company: FormFieldValue, printCapsNames: boolean): string {
    if (typeof company === "string") return printCapsNames ? company.toUpperCase() : company
    return ""
}